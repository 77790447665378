<template>
  <footer
    class="footer14-container thq-section-padding"
    v-bind:class="rootClassName"
  >
    <div class="footer14-max-width thq-section-max-width">
      <div class="thq-divider-horizontal"></div>
      <div class="footer14-content">
        <img :alt="logoAlt" :src="logoSrc" class="footer14-image1" />
        <div class="footer14-actions">
          <div class="footer14-newsletter">
            <span class="footer14-content1 thq-body-small">{{ content1 }}</span>
          </div>
          <div class="footer14-links">
            <div class="footer14-column1">
              <strong class="footer14-column1-title thq-body-large">
                {{ column1Title }}
              </strong>
              <div class="footer14-footer-links1">
                <span class="footer14-link1 thq-body-small">{{ link1 }}</span>
                <span class="footer14-link2 thq-body-small">{{ link2 }}</span>
                <span class="footer14-link3 thq-body-small">{{ link3 }}</span>
              </div>
            </div>
            <div class="footer14-column2">
              <strong class="footer14-column2-title thq-body-large">
                {{ column2Title }}
              </strong>
              <div class="footer14-footer-links2">
                <span class="footer14-link6 thq-body-small">{{ link6 }}</span>
                <span class="footer14-link7 thq-body-small">{{ link7 }}</span>
                <span class="footer14-link8 thq-body-small">{{ link8 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="thq-divider-horizontal"></div>
      <div class="footer14-social-links">
        <a
          href="https://www.facebook.com/ElodyFarmacie"
          target="_blank"
          rel="noreferrer noopener"
          class="footer14-link4"
        >
          <svg
            viewBox="0 0 877.7142857142857 1024"
            class="footer14-icon1 thq-icon-small"
          >
            <path
              d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
            ></path>
          </svg>
        </a>
        <a
          href="https://www.instagram.com/elody_farmacia/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          class="footer14-link5"
        >
          <svg
            viewBox="0 0 877.7142857142857 1024"
            class="footer14-icon3 thq-icon-small"
          >
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
            ></path>
          </svg>
        </a>
        <a
          href="https://www.youtube.com/channel/UCQbEBd7EJ5iMTK0ERCJK5hQ"
          target="_blank"
          rel="noreferrer noopener"
          class="footer14-link9"
        >
          <svg viewBox="0 0 1024 1024" class="footer14-icon5 thq-icon-small">
            <path
              d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
            ></path>
          </svg>
        </a>
      </div>
      <div class="footer14-credits1">
        <div class="footer14-credits2"></div>
        <span class="footer14-content3 thq-body-small">{{ copyright }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer14',
  props: {
    logoAlt: {
      type: String,
      default: 'logo',
    },
    link1: {
      type: String,
      default: 'Republica Moldova',
    },
    logoSrc: {
      type: String,
      default: '/img/logo-700w.webp',
    },
    link8: {
      type: String,
      default: '(+37322)461818 – info',
    },
    link3: {
      type: String,
      default: 'str. Calea Orheiului 111/8',
    },
    link2: {
      type: String,
      default: 'mun. Chișinău',
    },
    column2Title: {
      type: String,
      default: 'CONTACTE',
    },
    rootClassName: String,
    link7: {
      type: String,
      default: '(+373 22) 000 915 – oficiul',
    },
    link6: {
      type: String,
      default: 'i.cebotari@elody.md',
    },
    copyright: {
      type: String,
      default: '© ELODY.MD | All Rights reserved',
    },
    content1: {
      type: String,
      default:
        'Misiunea noastră este de a satisface necesitățile clienților prin personal profesionist, produse de înaltă calitate, mărci apreciate în toată lumea și servicii la cel mai înalt nivel',
    },
    column1Title: {
      type: String,
      default: 'ADRESA',
    },
  },
}
</script>

<style scoped>
.footer14-container {
  width: 1200px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.footer14-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.footer14-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}
 
.footer14-image1 {
  width: 189px;
  height: 106px;
  align-self: center;
}
 
.footer14-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  justify-content: center;
}
 
.footer14-newsletter {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.footer14-content1 {
  font-family: "Onest";
}
 
.footer14-links {
  flex: 1;
  display: flex;
}
 
.footer14-column1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.footer14-column1-title {
  font-family: "Onest";
}
 
.footer14-footer-links1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.footer14-link1 {
  font-family: "Onest";
}
 
.footer14-link2 {
  font-family: "Onest";
}
 
.footer14-link3 {
  font-family: "Onest";
}
 
.footer14-column2 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.footer14-column2-title {
  align-self: center;
  font-family: "Onest";
}
 
.footer14-footer-links2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.footer14-link6 {
  font-family: "Onest";
}
 
.footer14-link7 {
  font-family: "Onest";
}
 
.footer14-link8 {
  font-family: "Onest";
}
 
.footer14-social-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
 
.footer14-link4 {
  display: contents;
}
 
.footer14-icon1 {
  text-decoration: none;
}
 
.footer14-link5 {
  display: contents;
}
 
.footer14-icon3 {
  text-decoration: none;
}
 
.footer14-link9 {
  display: contents;
}
 
.footer14-icon5 {
  text-decoration: none;
}
 
.footer14-credits1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.footer14-credits2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.footer14-content3 {
  align-self: center;
  font-family: "Onest";
}
 

 
@media(max-width: 991px) {
  .footer14-newsletter {
    width: 50%;
  }
  .footer14-column1 {
    max-width: 100%;
  }
  .footer14-column2 {
    max-width: 100%;
  }
  .footer14-social-links {
    align-self: center;
  }
  .footer14-credits1 {
    flex-direction: column;
  }
  .footer14-content3 {
    align-self: center;
  }
}
 
@media(max-width: 767px) {
  .footer14-content {
    gap: var(--dl-space-space-twounits);
    width: auto;
    align-self: stretch;
    flex-direction: column;
  }
  .footer14-actions {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer14-newsletter {
    width: 100%;
  }
  .footer14-column1 {
    align-items: center;
  }
  .footer14-column1-title {
    text-align: center;
  }
  .footer14-footer-links1 {
    align-self: center;
  }
  .footer14-column2 {
    align-items: center;
  }
  .footer14-column2-title {
    text-align: center;
  }
  .footer14-footer-links2 {
    align-self: center;
  }
  .footer14-social-links {
    align-self: center;
  }
  .footer14-credits1 {
    flex-direction: column;
  }
  .footer14-credits2 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: auto;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer14-content3 {
    align-self: center;
  }
  .footer14root-class-name {
    width: auto;
  }
}
 
@media(max-width: 479px) {
  .footer14-content {
    gap: var(--dl-space-space-unit);
  }
  .footer14-image1 {
    width: 50%;
    height: 50%;
  }
  .footer14-links {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .footer14-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer14-footer-links1 {
    align-items: center;
    justify-content: center;
  }
  .footer14-column2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer14-footer-links2 {
    align-items: center;
    justify-content: center;
  }
  .footer14-social-links {
    align-items: center;
    justify-content: center;
  }
  .footer14-credits1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .footer14root-class-name {
    width: auto;
  }
}
</style>
