<template>
  <div class="home-container1">
    <div data-modal="practices" class="home-modal">
      <div class="home-practices">
        <div class="home-heading1">
          <span class="home-header1">Our practices</span>
          <svg viewBox="0 0 1024 1024" data-close="practices" class="home-close">
            <path
              d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"
            ></path>
          </svg>
        </div>
        <div class="home-grid">
          <div class="home-section1">
            <div class="home-heading2">
              <span class="home-header2">Cardiology</span>
              <span class="home-caption1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text1">Read more</span>
              <img alt="image" src="/img/arrow-2.svg" class="home-image1" />
            </div>
          </div>
          <div class="home-section2">
            <div class="home-heading3">
              <span class="home-header3">Orthopedics</span>
              <span class="home-caption2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text2">Read more</span>
              <img alt="image" src="/img/arrow-2.svg" class="home-image2" />
            </div>
          </div>
          <div class="home-section3">
            <div class="home-heading4">
              <span class="home-header4">Ophtalmology</span>
              <span class="home-caption3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text3">Read more</span>
              <img alt="image" src="/img/arrow-2.svg" class="home-image3" />
            </div>
          </div>
          <div class="home-section4">
            <div class="home-heading5">
              <span class="home-header5">Pediatrics</span>
              <span class="home-caption4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text4">Read more</span>
              <img alt="image" src="/img/arrow-2.svg" class="home-image4" />
            </div>
          </div>
          <div class="home-section5">
            <div class="home-heading6">
              <span class="home-header6">Nutrition</span>
              <span class="home-caption5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text5">Read more</span>
              <img alt="image" src="/img/arrow-2.svg" class="home-image5" />
            </div>
          </div>
          <div class="home-section6">
            <div class="home-heading7">
              <span class="home-header7">General</span>
              <span class="home-caption6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text6">Read more</span>
              <img alt="image" src="/img/arrow-2.svg" class="home-image6" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <header class="home-header8">
      <header data-thq="thq-navbar" class="home-navbar">
        <div class="home-left">
          <img
            alt="image"
            srcset="/img//logo-1500h.webp 1200w, /img/logo-tablet.webp 800w, /img/logo-mobile.webp 480w"
            sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
            src="/img/logo-1500h.webp"
            class="home-logo"
          />
          <nav class="home-links">
            <a
              href="https://farmacie.md/ro/filialy-aptek"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link1"
            >
              Despre Noi
            </a>
            <a
              href="https://farmacie.md/ro/filialy-aptek"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link2"
            >
              Farmacii
            </a>
            <a
              href="https://farmacie.md/ro/filialy-aptek"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link3"
            >
              Contacte
            </a>
          </nav>
        </div>
        <div data-thq="thq-navbar-btn-group" class="home-right">
          <button class="home-phone button">
            <span class="home-text7">+373 22 000 915</span>
          </button>
          <button class="home-book1 button button-main">
            <a
              href="https://farmacie.md/ro/filialy-aptek"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link4"
            >
              Vezi lista farmaciilor
            </a>
          </button>
        </div>
        <div data-thq="thq-burger-menu" class="home-burger-menu">
          <svg viewBox="0 0 1024 1024" class="home-icon2">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" class="home-mobile-menu">
          <div data-thq="thq-mobile-menu-nav" data-role="Nav" class="home-nav1">
            <div class="home-container2">
              <img
                alt="image"
                srcset="/img//logo-1500h.webp 1200w, /img/logo-tablet.webp 800w, /img/logo-mobile.webp 480w"
                sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
                src="/img/logo-1500h.webp"
                class="home-image7"
              />
              <div data-thq="thq-close-menu" class="home-menu-close">
                <svg viewBox="0 0 1024 1024" class="home-icon4">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              class="home-nav2"
            >
              <a
                href="https://farmacie.md/ro/page/gaseste-o-farmacie/"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link5"
              >
                Despre Noi
              </a>
              <a
                href="https://farmacie.md/ro/page/gaseste-o-farmacie/"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link6"
              >
                Farmacii
              </a>
              <span class="home-text8">Contacte</span>
              <button class="home-book2 button button-main">
                <a
                  href="https://farmacie.md/ro/page/gaseste-o-farmacie/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="home-link7"
                >
                  Vezi lista farmaciilor
                </a>
              </button>
            </nav>
          </div>
        </div>
      </header>
    </header>
    <section class="home-hero">
      <app-gallery12 rootClassName="gallery12root-class-name"></app-gallery12>
    </section>
    <div class="home-download">
      <div class="home-main">
        <iframe
          src="https://www.youtube.com/embed/GMYw42ZYCVA?si=lyzEBECMtJJtOWoa"
          allow="accelerometer, autoplay"
          class="home-iframe"
        ></iframe>
      </div>
    </div>
    <app-footer14 rootClassName="footer14root-class-name"></app-footer14>
  </div>
</template>

<script>
import AppGallery12 from '../components/gallery12'
import AppFooter14 from '../components/footer14'

export default {
  name: 'Home',
  props: {},
  components: {
    AppGallery12,
    AppFooter14,
  },
  metaInfo: {
    title: 'Rețea de farmacii Elody - atenție desăvîrișită ..',
    meta: [
      {
        name: 'description',
        content:
          'Elody este o rețea de farmacii dedicată sănătății și bunăstării tale, oferind medicamente de calitate și sfaturi profesionale pentru o viață mai sănătoasă.',
      },
      {
        property: 'og:title',
        content: 'Rețea de farmacii Elody - atenție desăvîrișită ..',
      },
      {
        property: 'og:description',
        content:
          'Elody este o rețea de farmacii dedicată sănătății și bunăstării tale, oferind medicamente de calitate și sfaturi profesionale pentru o viață mai sănătoasă.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a3e14210-5225-42e5-8b7e-ce6b7b81cc44/05916235-6706-40a9-99fb-ee0ea456bb11?org_if_sml=1&q=80&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.home-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
}
 
.home-modal {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 200;
  position: fixed;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  backdrop-filter: blur(4px);
  justify-content: center;
}
 
.home-practices {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 0px 0px 25px -2px rgba(66, 68, 90, 0.4);
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
 
.home-heading1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
 
.home-header1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
 
.home-close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: 0.3s;
}
 
.home-close:hover {
  opacity: 0.5;
}
 
.home-grid {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
 
.home-section1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
 
.home-heading2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-header2 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
 
.home-caption1 {
  max-width: 385px;
  line-height: 24px;
}
 
.home-text1 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
 
.home-image1 {
  width: 12px;
  object-fit: cover;
}
 
.home-section2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
 
.home-heading3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-header3 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
 
.home-caption2 {
  max-width: 385px;
  line-height: 24px;
}
 
.home-text2 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
 
.home-image2 {
  width: 12px;
  object-fit: cover;
}
 
.home-section3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
 
.home-heading4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-header4 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
 
.home-caption3 {
  max-width: 385px;
  line-height: 24px;
}
 
.home-text3 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
 
.home-image3 {
  width: 12px;
  object-fit: cover;
}
 
.home-section4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
 
.home-heading5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-header5 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
 
.home-caption4 {
  max-width: 385px;
  line-height: 24px;
}
 
.home-text4 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
 
.home-image4 {
  width: 12px;
  object-fit: cover;
}
 
.home-section5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
 
.home-heading6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-header6 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
 
.home-caption5 {
  max-width: 385px;
  line-height: 24px;
}
 
.home-text5 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
 
.home-image5 {
  width: 12px;
  object-fit: cover;
}
 
.home-section6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
 
.home-heading7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-header7 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
 
.home-caption6 {
  max-width: 385px;
  line-height: 24px;
}
 
.home-text6 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
 
.home-image6 {
  width: 12px;
  object-fit: cover;
}
 
.home-header8 {
  width: 100%;
  display: flex;
  justify-content: center;
}
 
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
 
.home-left {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-logo {
  width: 165px;
  object-fit: cover;
}
 
.home-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-link1 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.home-link1:hover {
  opacity: 0.5;
}
 
.home-link2 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.home-link2:hover {
  opacity: 0.5;
}
 
.home-link3 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.home-link3:hover {
  opacity: 0.5;
}
 
.home-right {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.home-phone {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
}
 
.home-text7 {
  line-height: 24px;
}
 
.home-book1 {
  text-decoration: none;
  background-color: #ca2a69;
}
 
.home-link4 {
  font-family: "Inter";
  text-decoration: none;
}
 
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
 
.home-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
 
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
 
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
 
.home-image7 {
  height: 2rem;
}
 
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
 
.home-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.home-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.home-text8 {
  margin-bottom: var(--dl-space-space-unit);
}
 
.home-book2 {
  text-decoration: none;
}
 
.home-link7 {
  font-family: Inter;
  text-decoration: none;
}
 
.home-hero {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
 
.home-download {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.home-main {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
}
 
.home-iframe {
  width: 1200px;
  height: 667px;
}
 
@media(max-width: 991px) {
  .home-navbar {
    z-index: 1000;
  }
  .home-links {
    display: none;
  }
  .home-right {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-round);
    background-color: #ca2a69;
  }
  .home-icon2 {
    fill: white;
  }
  .home-image7 {
    width: 150px;
    height: 84px;
  }
  .home-hero {
    flex: 1;
    height: 0px;
  }
}
 
@media(max-width: 767px) {
  .home-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-burger-menu {
    display: flex;
  }
  .home-book2 {
    background-color: #ca2a69;
  }
  .home-link7 {
    font-family: Inter;
  }
  .home-main {
    align-items: center;
    flex-direction: column-reverse;
  }
  .home-iframe {
    width: 767px;
  }
}
 
@media(max-width: 479px) {
  .home-navbar {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-iframe {
    width: 478px;
    height: 265px;
  }
}
</style>
