<template>
  <div
    class="gallery12-gallery3 thq-section-padding"
    v-bind:class="rootClassName"
  >
    <div class="gallery12-max-width thq-section-max-width">
      <div class="gallery12-content">
        <div class="gallery12-container1">
          <a
            href="https://farmacie.md/ro/page/gaseste-o-farmacie"
            target="_blank"
            rel="noreferrer noopener"
            class="gallery12-link1"
          >
            <img
              :alt="image1Alt"
              :src="image1Src"
              class="gallery12-image1 thq-img-ratio-1-1"
            />
          </a>
          <a
            href="https://farmacie.md/ro/page/gaseste-o-farmacie/"
            target="_blank"
            rel="noreferrer noopener"
            class="gallery12-link2"
          >
            <img
              :alt="image2Alt"
              :src="image2Src"
              class="gallery12-image2 thq-img-ratio-1-1"
            />
          </a>
        </div>
        <div class="gallery12-container2">
          <a
            href="https://farmacie.md/ro/"
            target="_blank"
            rel="noreferrer noopener"
            class="gallery12-link3"
          >
            <img
              :alt="image3Alt"
              :src="image3Src"
              class="gallery12-image3 thq-img-ratio-4-3"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery12',
  props: {
    image1Src: {
      type: String,
      default: '/img/og-1400w.webp',
    },
    rootClassName: String,
    image2Src: {
      type: String,
      default: '/img/harta-1400w.webp',
    },
    image1Alt: {
      type: String,
      default: 'PlaceholderImage1302',
    },
    image2Alt: {
      type: String,
      default: 'PlaceholderImage1302',
    },
    image3Alt: {
      type: String,
      default: 'PlaceholderImage1302',
    },
    image3Src: {
      type: String,
      default: '/img/fmd-200h.webp',
    },
  },
}
</script>

<style scoped>
.gallery12-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
 
.gallery12-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.gallery12-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.gallery12-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.gallery12-link1 {
  display: contents;
}
 
.gallery12-image1 {
  width: 823px;
  height: 488px;
  align-self: center;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  animation-name: none;
  animation-delay: 0s;
  text-decoration: none;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.gallery12-link2 {
  display: contents;
}
 
.gallery12-image2 {
  width: 361px;
  height: 472px;
  align-self: center;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  text-decoration: none;
}
 
.gallery12-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.gallery12-link3 {
  display: contents;
}
 
.gallery12-image3 {
  width: 1200px;
  height: 118px;
  z-index: 100;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  text-decoration: none;
}
 
.gallery12root-class-name {
  width: auto;
  height: auto;
}
 
@media(max-width: 991px) {
  .gallery12-image3 {
    width: 990px;
  }
  .gallery12root-class-name {
    width: auto;
  }
}
 
@media(max-width: 767px) {
  .gallery12-container1 {
    flex-direction: column;
  }
  .gallery12-image1 {
    flex: auto;
  }
  .gallery12-image2 {
    flex: auto;
  }
  .gallery12-image3 {
    width: 766px;
  }
}
 
@media(max-width: 479px) {
  .gallery12-image1 {
    display: none;
  }
  .gallery12-image3 {
    width: 478px;
  }
  .gallery12root-class-name {
    width: auto;
  }
}
</style>
